<template>
  <div>
     
        <form class="form-search" @submit.prevent="search" id="news-search">
            <div class="input-append">
                <input 
                    type="text" 
                    class="input-medium search-query"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                    :placeholder="$t('app.search')"
                    v-model="row.keyword">
                <button 
                    type="submit" 
                    class="add-on">
                    <i class="icon-icons185"></i>
                </button>
            </div>
        </form>

  </div>
</template>

<script>
export default {
    name: 'SocialCard',
    props: [],
    data(){
        return {
            row: {
                keyword: ''
            }
        }
    },
    methods: {
        
        search() {
            this.$router.push({name: 'news', query:{keyword: this.row.keyword }})
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
